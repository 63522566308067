import React, { useState, useEffect } from 'react';
import api from "../../../api/Api";
import Line from '../../../assets/img/hline.png';
import { Link } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";

export default function ConsultantHistory() {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.getCompanies()
            .then(setCompanies)
            .finally(() =>
                setTimeout(() => {
                    setLoading(false);
                }, 500)
            );
    }, []);

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <CircularProgress size={50} thickness={4} style={{ color: '#ff6500' }} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="col mx-45 side-menu-padding-top roadmap-history">
                <div className="home-container">
                    <div className="archive-container">
                        <div className="archive-supervision">
                            <h3> VOTRE HISTORIQUE</h3>
                            <p className="text-center">Elément scanné / Date du scan / Feuilles de route</p>
                            <img className="img-line" src={Line} alt="" />
                            <ul>
                                {companies && companies.map((item, i) => (
                                    <div className="archive-supervision-div py-1" key={i}>

                                        <span className="domain-span">{item.name}</span>
                                        <Link
                                            className="text-white text-decoration-none"
                                            to={{
                                                pathname: `/roadmap-history/${item.id}`,
                                                state: {
                                                    title: item.name,
                                                },
                                            }}
                                        >
                                            <div
                                                className={`history-number-btn`}
                                            >
                                                <h6>{item.count_scans}</h6>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}