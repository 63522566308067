import Paper from "@material-ui/core/Paper";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import React from "react";

export default class ScheduleView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      date: new Date(),
    };
  }


  getSchedules = (date) => {
    this.props.getSchedules(date);
  }
  
  componentDidUpdate() {
    this.setState({data: this.props.data})
  }

  onCurrentDateChange = (currentDate) => {
    this.getSchedules(currentDate);
  };

  render() {
    const { data, date } = this.state;
    return (
      <Paper elevation={3}>
        <Scheduler data={data} height={660}>
          <ViewState
            defaultCurrentViewName="Week"
            defaultCurrentDate={date}
            onCurrentDateChange={this.onCurrentDateChange}
          />
          <WeekView
            timeTableCellComponent={(props) => {
              return (
                <WeekView.TimeTableCell
                  {...props}
                  onDoubleClick={() =>
                    this.props.onCellDoubleClick({
                      start: props.startDate,
                      end: props.endDate,
                    })
                  }
                />
              );
            }}
          />
          <MonthView
            timeTableCellComponent={(props) => {
              return (
                <MonthView.TimeTableCell
                  {...props}
                  onDoubleClick={this.props.onCellDoubleClick}
                />
              );
            }}
          />
          <Appointments
            appointmentComponent={(props) => {
              return (
                <Appointments.Appointment
                  {...props}
                  onDoubleClick={() => console.log(props)}
                />
              );
            }}
          />

          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />

          <AppointmentTooltip showCloseButton />
        </Scheduler>
      </Paper>
    );
  }
}
