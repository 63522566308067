export default function ErrorMessages({ errors }) {
  return (
    <>
      {errors &&
        errors.map((item, i) => (
          <div
            key={i}
            id="validationServerUsernameFeedback"
            className="alert si-form-alert"
          >
            <div className="col-3 error">
              <div className="notification-error"></div>
              <div className="error-msg">Error!</div>
            </div>
            <div className="col-9">{item}</div>
          </div>
        ))}
    </>
  );
}

// This function can be used to extract generically errors from the api in to a list of strings.
ErrorMessages.extractResponse = (response) => {
  if (!response) {
    return [];
  }
  if (response.status === 400) {
    return Object.values(response.data).map(error => error);
  }
  return ['Unknown Error'];
};
