import React, { useState, useRef, useEffect } from "react";
import noImg from "../../../assets/img/no-img.jpg";
import UserContext from '../../../UserContext';
import { ErrorMessage, Formik } from 'formik';
import * as yup from 'yup';
import api from "../../../api/Api";

export default function UploadImage(props) {

    const fileInput = useRef(null);
    const [uploadedImg, setUploadedImg] = useState();
    const { user, setUser } = React.useContext(UserContext);

    useEffect(() => {
        api.getCurrentUser()
            .then((response) => {
                if (response && response.image) {
                    setUploadedImg(response.image)
                }
            })
    }, []);

    const handleSubmit = (values, { setSubmitting, ...config }) => {
        api.uploadUserImage(values, user.id).then((response) => {
            api.getCurrentUser()
                .then((response) => {
                    setUser(response);
                })
        })
    };

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

    return (
        <div className="col mx-45 my-auto">
            <h3 className="title text-center text-white mb-100">Changement de votre photo</h3>
            <Formik
                initialValues={{ image: '' }}
                validationSchema={
                    yup.object().shape({
                        image: yup.mixed().required().test('fileType', "Your Error Message", value => SUPPORTED_FORMATS.includes(value.type))
                    }
                    )}
                onSubmit={handleSubmit}
            >
                {({ errors, isSubmitting, values, ...formik }) => {
                    return (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-6 d-flex">
                                    <div className="img-container">
                                        {uploadedImg
                                            ? <img src={uploadedImg} alt="cv" />
                                            : <img src={noImg} />
                                        }

                                    </div>
                                </div>
                                <div className="col-6 d-flex justify-content-around flex-column align-items-start file-container">
                                    <div className="file-chooser mt-3">
                                        <input
                                            name="image"
                                            type="file"
                                            id="formFile"
                                            ref={fileInput}
                                            className={'form-control ' + (errors.image ? 'is-invalid' : '')}
                                            onChange={(event) => {
                                                formik.setFieldValue('image', event.currentTarget.files[0]);
                                                setUploadedImg(URL.createObjectURL(event.currentTarget.files[0]));
                                            }}
                                            accept="image/png, image/jpeg, image/jpg"
                                        />
                                        <ErrorMessage component="div" name="image" className="invalid-feedback" />
                                    </div>
                                    <small>Maximum : 1 MB, 1000 pixels x 1000 pixels</small>
                                    <button type="submit" className="btn si-primary-button px-4 mb-3">
                                        <div className="btn-text">Suivre</div>
                                        <div className="right-arrow-icon"></div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </div>
    )
}