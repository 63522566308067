import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import Line from '../../../assets/img/hline.png';
import infoNotChecked from '../../../assets/img/svg/checked.png';
import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

export default function RoadmapHistory(props) {
    const [roadmaps, setRoadmaps] = useState();
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const { REACT_APP_APIURL } = process.env;

    useEffect(() => {
        api.getRoadmapsByScan(props.match.params.scanId).then(setRoadmaps)
            .finally(() => setTimeout(() => {
                setLoading(false)
            }, 500));
    }, [props.match.params.scanId]);

    const formatDate = (date) => {
        return new Date(date);
    };

    const handlePdfDownload = (roadmapId) => {
        api.createUniqueKey(props.match.params.scanId)
            .then((response) => {
                if (response) {
                    const win = window.open(`${REACT_APP_APIURL}/api/pdf/scans/${props.match.params.scanId}/roadmap/?key=${response.key}&roadmap=${roadmapId}`, "_blank")
                    win.focus();
                }
            });
    }

    const title = props.location.state.title
    const date = props.location.state.date

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <CircularProgress
                        size={50}
                        thickness={4}
                        style={{ color: "#ff6500" }}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div className="col mx-45 my-auto roadmap-history">
                <div className="title-scan-history text-center mb-0">

                    <h2>Serveur : {title}</h2>
                    {roadmaps && roadmaps.length > 0 &&
                        (
                            <h3>FEUILLES DE ROUTE</h3>
                        )}
                    <p>Suite au scan du {date}</p>
                </div>
                <img className="img-line w-25 mb-5" src={Line} alt="" />
                <div className="home-container">
                    <div className="archive-container">
                        <div className="archive-supervision roadmap-supervision">
                            {roadmaps && roadmaps.length > 0
                                ? (
                                    <ul>
                                        {roadmaps.map((elem, j) => (
                                            <div className="archive-supervision-div mb-2" key={j}>
                                                <span>{elem.name}</span>
                                                <span>
                                                    <span className="date">{formatDate(elem.updated).toLocaleDateString('en-GB')}</span>
                                                </span>
                                                <img src={infoNotChecked} alt="info" onClick={() => handlePdfDownload(elem.id)} />
                                            </div>
                                        ))}
                                    </ul>
                                )
                                : (
                                    <h5 className="text-center">Pas de feuille de route</h5>
                                )
                            }
                        </div>
                    </div>
                    <button
                        className="btn si-primary-button mx-auto px-4 mt-5"
                        onClick={() => history.push("/roadmap-history")}
                    >
                        <div className="left-arrow-icon"></div>
                        <div className="btn-text">RETOUR</div>
                    </button>
                </div>
            </div>
        );
    }
}
