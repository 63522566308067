
import React, { useState } from "react";
import logoImg from '../../assets/img/logo.png';
import { ErrorMessage, Formik, Field } from "formik";
import * as Yup from "yup";
import api from "../../api/Api";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PasswordResetConfirm(props) {
    const [loadingButton, setLoadingButton] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleConfirmPassword = () => {
        setConfirmPasswordShown(!confirmPasswordShown);
    };
    const query = useQuery();

    return (
        <Formik
            initialValues={{
                password: "",
                repeatPassword: ""
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().required("Required"),
                repeatPassword: Yup.string().required("Required").oneOf([Yup.ref('password'), null], 'Passwords must match')
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setLoadingButton(true);
                let data = {
                    token: query.get('token'),
                    password: values["password"]
                }
                api.confirmPasswordReset(data)
                    .then(() => {
                        setLoadingButton(false);
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                    })
                    .finally(() => setTimeout(() => {
                        window.location.href = "/"
                    }, 1000));
            }}
        >
            {(props) => {
                const { errors, handleSubmit } = props;
                return (
                    <div className="height-90vh">
                        <div className="login-page-container h-100">
                            <div className="content">
                                <div className="logo-container">
                                    <img src={logoImg} class="logo-img" alt="logo-img" />
                                </div>
                                <div className="form-title">Réinitialisation de votre mot de passe</div>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3 input-group">
                                        <Field
                                            name="password"
                                            type={passwordShown ? "text" : "password"}
                                            placeholder="Entrez le nouveau mot de passe"
                                            className={
                                                "form-control" + (errors.password ? " is-invalid" : "")
                                            }
                                        />
                                        <button
                                            className="btn hs-pw"
                                            type="button"
                                            onClick={togglePassword}
                                            id="button-addon2"
                                        >
                                            <div
                                                className={
                                                    passwordShown
                                                        ? "show-password-icon"
                                                        : "hide-password-icon"
                                                }
                                            ></div>
                                        </button>
                                        <ErrorMessage
                                            component="div"
                                            name="password"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="mb-5 input-group">
                                        <Field
                                            name="repeatPassword"
                                            type={confirmPasswordShown ? "text" : "password"}
                                            placeholder="Confirmez le nouveau mot de passe"
                                            className={
                                                "form-control" + (errors.repeatPassword ? " is-invalid" : "")
                                            }
                                        />
                                        <button
                                            className="btn hs-pw"
                                            type="button"
                                            onClick={toggleConfirmPassword}
                                            id="button-confirm"
                                        >
                                            <div
                                                className={
                                                    confirmPasswordShown
                                                        ? "show-password-icon"
                                                        : "hide-password-icon"
                                                }
                                            ></div>
                                        </button>
                                        <ErrorMessage
                                            component="div"
                                            name="repeatPassword"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className={
                                            "btn si-primary-button w-100" +
                                            (loadingButton ? " si-loading" : "")
                                        }
                                    >
                                        <div className="btn-text">
                                            {loadingButton ? "Loading..." : "ENVOYER"}
                                        </div>
                                        <div
                                            hidden={loadingButton ? true : false}
                                            className="right-arrow-icon"
                                        ></div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                )
            }}
        </Formik>
    );
};