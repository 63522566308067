import React, { useState } from 'react';

import { ErrorMessage, Formik, Field } from 'formik';
import * as Yup from 'yup';
import api from '../../../api/Api';
import { Link } from 'react-router-dom';

const ValidatedLoginForm = ({ setToken, setUser }) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [errorsMessage, setErrors] = useState();
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required('Required'),
                password: Yup.string().required('No password provided.'),
                // .min(8, "Password is too short - should be 8 chars minimum.")
                // .matches(/(?=.*[0-9])/, "Password must contain a number."),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setLoadingButton(true);
                api.loginUser(values)
                    .then((token) => {
                        if (token) {
                            setToken(token);
                            api.getCurrentUser().then((user) => {
                                setUser(user);
                            });
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.headers['content-type'] === 'application/json') {
                            const errors = [];
                            for (const value of Object.values(error.response.data)) {
                                errors.push(value);
                            }
                            var errorsList = [].concat.apply([], errors);
                            setErrors(errorsList);
                            setLoadingButton(false);
                        } else {
                            setErrors(['Unknown Error']);
                            setLoadingButton(false);
                        }
                    });
            }}
        >
            {(props) => {
                const { errors, handleSubmit } = props;
                return (
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <Field
                                    name="email"
                                    placeholder="Adresse Mail"
                                    className='form-control'
                                />
                            </div>

                            <div className="mb-3 input-group">
                                <Field
                                    name="password"
                                    type={passwordShown ? 'text' : 'password'}
                                    placeholder="Mot de passe"
                                    className='form-control'
                                />
                                <button className="btn hs-pw" type="button" onClick={togglePassword} id="button-addon2">
                                    <div className={passwordShown ? 'show-password-icon' : 'hide-password-icon'}></div>
                                </button>
                            </div>

                            {/* Remember me buttons */}
                            <div className="mb-4 form-check">
                                <div className="cb">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
                                    <label className="form-check-label" htmlFor="exampleCheck1">
                                        Rester connecté
                                    </label>
                                </div>
                                {/* <a href="/" className="password-reset">
                                Mot de passe oublie?
                              </a> */}
                                <Link to="/password-reset" className="password-reset">
                                    Mot de passe oublié >
                                </Link>
                            </div>

                            <button
                                type="submit"
                                className={'btn si-primary-button w-100' + (loadingButton ? ' si-loading' : '')}
                            >
                                <div className="btn-text">{loadingButton ? 'Loading...' : 'Valider'}</div>
                                <div hidden={loadingButton ? true : false} className="right-arrow-icon"></div>
                            </button>
                        </form>
                        {((errorsMessage) || (errors.email) || (errors.password)) && (
                            <div id="validationServerUsernameFeedback" className="alert si-form-alert mt-5">
                                <div className="col-4 error">
                                    <div className="notification-error"></div>
                                    <div className="error-msg">Attention!</div>
                                </div>
                                <div className="col-8">Ces coordonnées sont erronées.</div>
                            </div>
                        )}
                    </div>
                );
            }}
        </Formik>
    );
};

export default ValidatedLoginForm;
