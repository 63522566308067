
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import vLine from '../../assets/img/vline.png';
import imgLogo from "../../assets/img/d9e72-icon-1.png"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function InfoModal({
    handleCloseInfoModal = (f) => f,
    infoModal = false,
    ...props
}) {

    return (
        <Dialog
            open={infoModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseInfoModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="info-modal-container"
        >
            <div className="info-modal-content">
                <span onClick={handleCloseInfoModal} className="close-icon"></span>
                <DialogTitle id="alert-dialog-slide-title">
                    <span>{props.title}</span>
                </DialogTitle>
                <div className="img-text-content">
                    <img src={vLine} alt="" className="left-line" />
                    <DialogContent>
                        <div id="alert-dialog-slide-description">
                            <span>{props.text}</span>
                        </div>
                    </DialogContent>
                    <img src={vLine} alt="" className="right-line" />
                </div>
                <img src={imgLogo} alt="" />
            </div>
        </Dialog>
    );
}