import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export default function Dashboard() {
    const [companies, setCompanies] = useState([]);
    const [domains, setDomains] = useState([]);

    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);

    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getUserCompanies();
    }, []);

    const getUserCompanies = async () => {
        api.getCompanies().then((response) => {
            setCompanies(response);
        });
    };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setLoadingButton(true);
        history.push(`/dashboards/company/${selectedCompany}/domain/${selectedDomain}`);
    };
    
    const onCompaniesChange = (event) => {
        setSelectedCompany(event.target.value);
        const d = companies.find((x) => x.id === parseInt(event.target.value));
        if (d) {
            setDomains(d.domains);
        } else {
            setDomains([]);
        }
    };

    const onDomainChange = (event) => {
        setSelectedDomain(event.target.value);
    };

    return (
        <div className="col mx-45 side-menu-padding-top">
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="row ps-100">
                    <div className="col-6 pe-4">
                        <div className="col-content d-flex flex-column align-items-start">
                            <div className="title">Companies</div>
                            <select
                                {...register('companies', {
                                    required: 'This is required',
                                })}
                                className={'form-select' + (errors.companies ? ' is-invalid' : '')}
                                aria-label="Default select example"
                                onChange={onCompaniesChange}
                                required
                            >
                                <option value="">Open this select menu</option>
                                {companies &&
                                    companies.map((item, i) => (
                                        <option value={item.id} key={i}>
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            {errors.companies && <div className="invalid-feedback">{errors.companies.message}</div>}
                        </div>
                    </div>
                    <div className="col-6 ps-4 vline">
                        <div className="col-content d-flex flex-column align-items-end">
                            <div className="file-chooser">
                                <div className="title">Domains</div>
                                <select
                                    {...register('domains', {
                                        required: 'This is required',
                                    })}
                                    className={'form-select' + (errors.domains ? ' is-invalid' : '')}
                                    aria-label="Default select example"
                                    onChange={onDomainChange}
                                    required
                                >
                                    <option value="">Open this select menu</option>
                                    {domains &&
                                        domains.map((item, i) => (
                                            <option value={item.id} key={i}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                                {errors.domains && <div className="invalid-feedback">{errors.domains.message}</div>}
                            </div>
                        </div>
                        <div className="col-content d-flex flex-column align-items-end mt-100">
                            <button
                                type="submit"
                                className={'btn si-secondary-button sec-med' + (loadingButton ? ' si-loading' : '')}
                            >
                                {loadingButton ? 'Loading...' : 'View Dashboard'}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
