import './App.scss';
import TopNavbar from './compontents/App/TopNavbar/TopNavbar';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Login from './compontents/App/Login/Login';
import useToken from './compontents/App/useToken';
import UserContext from './UserContext';
import Consultant from './compontents/Consultant/Consultant';
import User from './compontents/User/User';
import PasswordReset from './compontents/global/PasswordReset';
import PasswordResetConfirm from './compontents/global/PasswordResetConfirm';

const userType = {
    consultant: 'consultant',
    user: 'user',
};

function App() {
    const { token, setToken, user, setUser } = useToken();
    document.title = 'SI';
    if (user) {
        if (user.user_type === userType.consultant) {
            document.title = 'SI Admin Consultant';
        } else if (user.user_type === userType.user) {
            document.title = 'SI Client';
        } else {
            document.title = 'SI';
        }
    }
    if (!token) {
        return (
            <div>
                <TopNavbar showUserPanel={false} />
                <Switch>
                    <Route exact path="/password-reset">
                        <PasswordReset />
                    </Route>
                    <Route exact path="/password-reset/confirm/" component={PasswordResetConfirm}></Route>
                    <Route path="*">
                        <Login setToken={setToken} setUser={setUser} />
                    </Route>
                </Switch>
            </div>
        );
    }
    return (
        <UserContext.Provider value={{user, setUser}}>
            <Switch>
                <Route exact path="/password-reset">
                    <TopNavbar showUserPanel={true} />
                    <PasswordReset />
                </Route>
                <Route exact path="/password-reset/confirm">
                    <TopNavbar showUserPanel={true} />
                    <PasswordResetConfirm />
                </Route>
                <Route path="*">
                    <TopNavbar showUserPanel={true} />
                    <div className="page-container">
                        <div className="content h-100">
                            <div className="row h-100 w-100 mx-0">
                                {user && user.user_type === userType.consultant ? <Consultant /> : <User />}
                            </div>
                        </div>
                    </div>
                </Route>
            </Switch>
        </UserContext.Provider>
    );
}

export default App;
