import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import SideMenu from './SideMenu/SideMenu';
import UserDashboard from './UserDashboard/UserDashboard';
import Apropos from './Apropos/Apropos';
import ScanHistory from './RoadmapHistory/ScanHistory';
import ScanDashboard from './UserDashboard/ScanDashboard';
import UserDashboardResults from './UserDashboard/UserDashboardResults';
import UserRoadmap from './UserRoadmap/UserRoadmap';
import UserNameRoadmap from './UserRoadmap/UserNameRoadmap';
import RoadmapHistory from "./RoadmapHistory/RoadmapHistory";
import UploadImage from './Profile/UploadImage';
import ChangeName from './Profile/ChangeName';

export default function User() {
    return (
        <BrowserRouter>
            <SideMenu />
            <Switch>
                <Route path="/a-propos">
                    <Apropos />
                </Route>
                <Route path="/dashboard">
                    <UserDashboard />
                </Route>
                <Route path="/dashboards/company/:id/type/:scanType" component={ScanDashboard}></Route>
                <Route path="/dashboards/:scanId/results" component={UserDashboardResults}></Route>
                <Route path="/scans/:scanId/roadmap/name" component={UserNameRoadmap}></Route>
                <Route path="/scans/:scanId/roadmap" component={UserRoadmap}></Route>
                <Route path="/roadmap-history" exact>
                    <ScanHistory />
                </Route>
                <Route path="/roadmap-history/:scanId" component={RoadmapHistory} exact></Route>
                <Route path="/profile/img" component={UploadImage}></Route>
                <Route path="/profile/name" component={ChangeName}></Route>
                <Route path="*">
                    <Redirect to="/a-propos" />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
