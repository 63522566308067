import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import GaugeChart from 'react-gauge-chart';
import { Link } from 'react-router-dom';

export default function ViewDashboard(props) {
    const [charts, setCharts] = useState();
    const [disabledCharts, setDisabledCharts] = useState();

    useEffect(() => {
        api.getLatestScans(props.match.params.id, props.match.params.domainId)
            .then((response) => {
                setCharts(response)
                if (response && response.length < 2) {
                    if (response[0]['scan_type'] === 'server') {
                        setDisabledCharts('website');
                    } else {
                        setDisabledCharts('server');
                    }
                }
            })
    }, [props.match.params.id, props.match.params.domainId]);


    const colorText = (value) => {
        if (value >= 75) {
            return 'brown';
        } else if (value >= 50 && value < 75) {
            return 'red';
        } else if (value >= 25 && value < 50) {
            return 'orange';
        } else {
            return 'green';
        }
    };
    const translateKey = (string) => {
        if (string === 'server') {
            return 'SERVEUR';
        } else if (string === 'website') {
            return 'SITE INTERNET';
        }
    };

    return (
        <div className="col mx-45 my-auto view-dashboard">
            <h3 className="title text-center mb-100">
                {charts && charts[0].company.name} - {charts && charts[0].domain.name}
            </h3>
            <div className="row justify-content-center">
                {charts &&
                    charts.map((item, i) => (
                        <div className="col-4 text-center gradient-right" key={i} style={{ maxWidth: '600px' }}>
                            <h3>{translateKey(item.scan_type)}</h3>
                            <GaugeChart
                                id="gauge-chart1"
                                percent={Math.min(item.calculated_risk / 100, 1)}
                                nrOfLevels={4}
                                formatTextValue={(value) => value}
                                colors={['#008000', '#d48e05', '#d46604', '#c90700']}
                                hideText={true}
                            />
                            <p className="gauge-text">
                                <span className="risque">Risque : </span>
                                <span className={`${colorText(item.calculated_risk)}`}>{item.calculated_risk}</span>
                            </p>
                            <Link className="text-decoration-none" to={`/dashboards/${item.id}/validate`}>
                                <button className="btn si-primary-button mx-auto px-4 mt-5">
                                    <div className="btn-text">{item.valid ? 'Suivi' : 'Valider'}</div>
                                    <div className="right-arrow-icon"></div>
                                </button>
                            </Link>
                        </div>
                    ))}
                {disabledCharts && (
                    <div className="col-4 text-center gradient-right" style={{ maxWidth: '600px' }}>
                        <h3>{translateKey(disabledCharts)}</h3>
                        <GaugeChart
                            id="gauge-chart1"
                            percent={0}
                            nrOfLevels={4}
                            colors={["#666666", "#666666", "#666666", "#666666"]}
                            hideText={true}
                        />
                        <p className="gauge-text">
                            <span className="risque">Risque : </span>
                            <span className="disabled-gauge">0</span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}
