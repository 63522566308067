
import React, { useState } from "react";
import logoImg from '../../assets/img/logo.png';
import { ErrorMessage, Formik, Field } from "formik";
import * as Yup from "yup";
import api from "../../api/Api";
import InfoModal from '../global/InfoModal';

export default function PasswordReset() {
    const [loadingButton, setLoadingButton] = useState(false);
    const [confirmText, setConfirmText] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);

    const handleClose = () => {
        setDisplayModal(false);
    }

    return (
        <Formik
            initialValues={{
                email: ""
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required("Required")
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setLoadingButton(true);
                api.passwordReset(values)
                    .then(() => {
                        setLoadingButton(false);
                        setConfirmText(true);
                    })
            }}
        >
            {(props) => {
                const { errors, handleSubmit } = props;

                return (
                    <div className="height-90vh">
                        <div className="login-page-container h-100">
                            <div className="content">
                                <div className="logo-container">
                                    <img src={logoImg} className="logo-img" alt="logo-img" />
                                </div>
                                {confirmText
                                    ? <div className="form-title">
                                        <h3>Message envoyé !​</h3>
                                        <p>Regardez dans vos courriers indésirables s’il n’apparaît pas dans votre courrier dans quelques minutes</p>
                                    </div>
                                    :
                                    <>
                                        <div className="form-title">Nous allons vous envoyer un lien pour créer un nouveau mot de passe.</div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-5">
                                                <Field
                                                    name="email"
                                                    placeholder="Votre adresse mail"
                                                    className={
                                                        "form-control" + (errors.email ? " is-invalid" : "")
                                                    }
                                                />
                                                <ErrorMessage
                                                    component="div"
                                                    name="email"
                                                    className="invalid-feedback"
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className={
                                                    "btn si-primary-button w-100" +
                                                    (loadingButton ? " si-loading" : "")
                                                }
                                            >
                                                <div className="btn-text">
                                                    {loadingButton ? "Loading..." : "ENVOYER"}
                                                </div>
                                                <div
                                                    hidden={loadingButton ? true : false}
                                                    className="right-arrow-icon"
                                                ></div>
                                            </button>
                                            <div className="ps-0 mb-4 mt-4 form-check">
                                                <div className="cb">
                                                    <label className="form-check-label" htmlFor="exampleCheck1" onClick={() => setDisplayModal(true)}>
                                                        Retour à l’accueil
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                        <InfoModal
                                            handleCloseInfoModal={handleClose}
                                            infoModal={displayModal}
                                            title={"Message envoyé!"}
                                            text={"Regardez dans vos courriers indésirables s’il n’apparaît pas dans votre courrier dans quelques minutes."}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                )
            }}
        </Formik>
    );
};