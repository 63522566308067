import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Apropos() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <CircularProgress size={50} thickness={4} style={{ color: '#ff6500' }} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="col mx-45 my-auto sa-content">
                <div className="agreements-box gradient-left gradient-right">
                    <div className="apropos-box">
                        <p>
                            Cette plateforme vous présente les résultats des <b>scans</b> de votre infrastructure, à
                            partir desquels vous pouvez construire des <b>feuilles de route</b>.
                        </p>
                        <p>
                            Un <b>scan</b> est une série de tests sur une partie de votre infrastructure, effectués par
                            un logiciel. Un scan a pour objectif de détecter des <b>vulnérabilités</b>.
                        </p>
                        <p>
                            Les <b>vulnérabilités</b> sont des points faibles par où un pirate peut pénétrer pour
                            effectuer une attaque de votre infrastructure. Les vulnérabilités sont classées par
                            sévérité, de 1 (bénigne, peut être corrigé mais sans urgence) à 10 (très grave, doit être
                            corrigée dès sa détection).
                        </p>
                        <p>
                            On distingue les scans <b>externes</b>, effectués depuis internet, et les scans{' '}
                            <b>internes</b>, effectués après avoir pénétré dans votre réseau (via un VPN et après
                            libération d’un accès de votre part)
                        </p>
                        <p>
                            Une <b>feuille de route</b> consiste en une <b>liste de vulnérabilités</b> que l’on a choisi
                            de corriger.
                        </p>
                        <p>
                            Au fur et à mesure de leur correction, on les coche dans la feuille de route, jusqu’à
                            l’avoir finie.
                        </p>
                        <p>
                            Le mécanisme de feuille de route permet un <b>choix rationnel des vulnérabilités</b> (en
                            commençant par les plus sévères en l’occurrence) et un suivi de ses actions.
                        </p>
                        <p>
                            Chaque écran de cette plateforme comprend un bouton « <b>Aide</b> » qui explique ce qui est{' '}
                            <b>important</b> ou ce qui doit être <b>accompli</b> dans la page. N’hésitez pas à
                            l’utiliser...
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
