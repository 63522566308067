import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import { Form } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import checkboxChecked from "../../../assets/img/svg/checkbox_on_light.png";
import infoNotChecked from "../../../assets/img/svg/checked2.png"
import UserDashboardModal from '../../global/UserDashboardModal';
import InfoModal from '../../global/InfoModal';
import FinishModalClose from '../../global/FinishModalClose';

export default function ValidateDashboard(props) {
    const [results, setResults] = useState([]);
    const [scan, setScan] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [critical, setCritical] = useState();
    const [validated, setValidated] = useState();
    const [displayModal, setDisplayModal] = useState();
    const [displayInfoModal, setDisplayInfoModal] = useState();
    const [displayRoadmapModal, setDisplayRoadmapModal] = useState();
    const [summary, setSummary] = useState("");
    const [solution, setSolution] = useState("");
    const [instances, setInstances] = useState();
    const [fait, setFait] = useState();

    useEffect(() => {
        api.getScansResults(props.match.params.scanId).then((response) => {
            setResults(response)
            api.getScansResults(props.match.params.scanId, true)
                .then((resp) => {
                    let vca = response.filter((el) => {
                        return !resp.find(rm => (rm._id === el._id));
                    })
                    setFait(vca);
                });
        });
        api.getFullScan(props.match.params.scanId).then(setScan);
        api.getRoadmapWithScan(props.match.params.scanId).then((response) => {
            setValidated(response);
            setCurrentIndex(response.items.length)
        }).catch((error) => console.log(error))

    }, [props.match.params.scanId]);

    const setBackground = (value) => {
        if (value >= 9) {
            return 'brown';
        } else if (value < 9 && value >= 7) {
            return 'red';
        } else if (value < 7 && value >= 5) {
            return 'orange';
        } else if (value < 5) {
            return 'green';
        }
    };

    const setCriticalBackground = (value) => {
        if (value === 4 || value === 5) {
            return 'brown';
        } else if (value === 2 || value === 3) {
            return 'orange';
        } else if (value === 1) {
            return 'green';
        }
    };

    const validateScan = (scanId) => {
        api.updateScanFields(scanId, { critical: critical })
        .then((response) => {
            setDisplayInfoModal(true);
            api.validateScan(scanId).then(setScan);
        });
    };

    const checkForValidation = (scanId) => {
        if (scan && scan.has_on_going_roadmap) {
            setDisplayRoadmapModal(true);
        } else {
            validateScan(scanId);
        }
    }

    const updateCritical = (scanId) => {
        api.updateScanFields(scanId, { critical: critical })
        .then(setScan);
    };

    const onCriticalChange = (event) => {
        setCritical(event.target.value);
    }

    const handleClose = () => {
        setDisplayModal(false);
    }

    const handleInfoClose = () => {
        setDisplayInfoModal(false);
    }

    const handleRoadmapModalClose = () => {
        setDisplayRoadmapModal(false);
    }

    const translateKey = (string) => {
        if (string === 'server') {
            return 'Serveur';
        } else if (string === 'website') {
            return 'Site Internet';
        }
    };
    return (
        <div className="col custom-content justify-content-between mt-0">
            <div className="title-results mt-auto mb-0">
                <h4>{scan && scan.company.name} - {scan && scan.domain.name} - {scan && translateKey(scan.scan_type)}</h4>
               
            </div>
            <div className="results-box agreements-box gradient-left gradient-right mt-auto">
                <div className="row">
                    <div className="col-12">
                        <div className="row text-grey title-rectangle consultant-rectangle">
                            <div className="col-auto ps-0 dash-info-col title-text">
                                Détail
                            </div>
                            <div className="col-auto dash-port-col title-text">Port</div>
                            <div className="col-6 dash-nature-col title-text">Nom de la vulnérabilité</div>
                            <div className="col-auto ms-auto title-text">
                                <span>Sévérité</span>
                            </div>
                            <div className="col-auto text-capitalize title-text">
                                <span>à faire</span>
                            </div>
                            <div className="col-auto title-text">
                                <span>Fait</span>
                            </div>
                        </div>
                        <div className="the-slector consultant-slector">
                            {results && results.length <= 0 &&
                                <h1 className="text-center mt-5">No result for this Scan</h1>
                            }
                            {results &&
                                results.map((item, i) => (
                                    <div
                                        className='slector-item align-items-center'
                                        key={i}
                                    >
                                        <p className="question-text text-port">
                                            <img width="26"
                                                height="26"
                                                src={infoNotChecked} alt="infoNotChecked2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDisplayModal(true);
                                                    setSummary(item.summary);
                                                    setSolution(item.solution);
                                                    setInstances(item.instances);
                                                }} />
                                        </p>
                                        <p className="question-text text-port p-port">{item.port}</p>
                                        <p className="question-text text-summary">{item.issue}</p>

                                        <div className="d-flex justify-content-end align-items-center w-100">
                                            <div className={"v-colored-rectangle bg-" + setBackground(item.value)}></div>
                                            <p className="impact-text ms-3">{item.value}</p>
                                            <div className="checkbox-div text-center a-faire">

                                                {validated && validated.items.filter((elem, i) => elem.result_id === item._id).map(() => {
                                                    let r = [];
                                                    r.push((
                                                        <div key={i}>
                                                            <img src={checkboxChecked} width="28" height="28"  className="ms-5" alt="checkbox" />
                                                        </div>
                                                    ))
                                                    return r;
                                                })}
                                            </div>
                                            <div className="checkbox-div text-center fait">

                                                {fait && fait.filter((elem, i) => elem._id === item._id).map(() => {
                                                    let r = [];
                                                    r.push((
                                                        <div key={i}>
                                                            <img src={checkboxChecked} className="pula" width="28" height="28" alt="checkbox" />
                                                        </div>
                                                    ))
                                                    return r;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w-100 roadmap-legend mt-auto">
                <div className="col-2 gradient-left color-legend">
                    <h6>Sévérité de la vulnérabilité détectée</h6>
                    <ul className="mb-0">
                        <li><div className="color-square bg-brown"></div>Très élevée</li>
                        <li><div className="color-square bg-red"></div>Elevée</li>
                        <li><div className="color-square bg-orange"></div>Moyenne</li>
                        <li><div className="color-square bg-green"></div>Faible</li>
                    </ul>
                </div>
                <div className="col-6">
                    <div className="col-10 mx-auto gradient-left px-12px">
                        <h5 className="risque-text d-flex">Nombre de paramètres: <span className="ms-auto">{currentIndex && currentIndex > 0 ? currentIndex : 0}</span></h5>
                        <h5 className="risque-text d-flex">Niveau de risque actuel: <span className={"ms-auto " + setBackground(scan && scan.calculated_risk / 10)}>{scan && scan.calculated_risk}</span></h5>
                        <h5 className="risque-text d-flex">
                            Niveau de risque après correction: <span className={"ms-auto " + setBackground(scan && scan.calculated_risk / 10)}>{scan && scan.calculated_risk}</span>
                        </h5>
                    </div>
                </div>
                <div className="col-4">
                    {scan && scan.critical !== 0
                        ?
                        <>
                        <h5 className="risque-text text-center">Criticité: <span className={setCriticalBackground(scan.critical)}>{scan.critical}</span></h5>
                            <FloatingLabel controlId="floatingSelect" label="Critical value" className="w-50 mx-auto mb-3">
                                <Form.Select aria-label="Floating label select example" onChange={onCriticalChange}>
                                    <option value="">Open this select menu</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Form.Select>
                            </FloatingLabel>
                        
                        <button
                            className="btn si-primary-button mx-auto px-4"
                            onClick={() => updateCritical(scan.id)}
                        >
                            <div className="btn-text">Valider Criticité</div>
                            <div className="right-arrow-icon"></div>
                        </button>
                        </>
                        :
                        <FloatingLabel controlId="floatingSelect" label="Critical value" className="w-50 mx-auto mb-3">
                            <Form.Select aria-label="Floating label select example" onChange={onCriticalChange}>
                                <option value="">Open this select menu</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>

                            </Form.Select>
                        </FloatingLabel>
                    }
                    {scan && !scan.valid && (
                        <button
                            className="btn si-primary-button mx-auto px-4"
                            onClick={() => checkForValidation(scan.id)}
                            disabled={critical ? false : true}
                        >
                            <div className="btn-text">Valider Analyse</div>
                            <div className="right-arrow-icon"></div>
                        </button>
                    )}
                </div>
            </div>
            <UserDashboardModal
                handleCloseInfoModal={handleClose}
                infoModal={displayModal}
                text={
                    <div className="user-dashboard-modal-text">
                        <h4>Nature de la vulnérabilité</h4>
                        <p className="mb-5">{summary}</p>
                        <h4>Action proposée</h4>
                        <p className="mb-5">{solution}</p>
                        {instances
                            && (
                                <>
                                    <h4>Composants Concernées</h4>
                                    {instances && instances.map((item, i) => {
                                        return (
                                            <p key={i}>{item.uri}</p>
                                        )
                                    })}
                                </>
                            )
                        }
                    </div>
                }

            />
            <InfoModal
                handleCloseInfoModal={handleInfoClose}
                infoModal={displayInfoModal}
                title={"Scan validation!"}
                text={"Scan has ben successfully validated."}
            />
            <FinishModalClose
                finishModalStatus={displayRoadmapModal}
                handleComplete={() => validateScan(scan.id)}
                handleCloseFinishModal={handleRoadmapModalClose}
                text={
                    <>
                        <p>You have ongoing roadmap! Are you sure you want to validate?</p>
                    </>
                }
                btnText={"Valider"}
            />
        </div>
    );
}
