import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import SideMenu from './SideMenu/SideMenu';
import Dashboard from './Dashboard/Dashboard';
import Calendar from './Calendar/Calendar';
import ViewDashboard from './ViewDashboard/ViewDashboard';
import UploadFile from './UploadFile/UploadFile';
import ValidateDashboard from './ValidateDashboard/ValidateDashboard';
import ConsultantHistory from './ConsultantHistory/ConsultantHistory';
import ConsultantRoadmapHistory from "./ConsultantHistory/ConsultantRoadmapHistory";
import ConsultantScanHistory from "./ConsultantHistory/ConsultantScanHistory";

export default function Consultant() {
    return (
        <BrowserRouter>
            <SideMenu />
            <Switch>
                <Route path="/upload">
                    <UploadFile />
                </Route>
                <Route path="/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/dashboards/company/:id/domain/:domainId" component={ViewDashboard}></Route>
                <Route path="/dashboards/:scanId/validate" component={ValidateDashboard}></Route>
                <Route path="/roadmap-history" exact component={ConsultantHistory}></Route>
                <Route path="/roadmap-history/:companyId" exact component={ConsultantScanHistory}></Route>
                <Route path="/roadmap-history/:companyId/:scanId" exact component={ConsultantRoadmapHistory}></Route>
                <Route path="/calendar">
                    <Calendar />
                </Route>
                <Route path="*">
                    <Redirect to="/upload" />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
