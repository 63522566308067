import React, { useState, useEffect } from 'react';
import api from "../../../api/Api";
import infoChecked from "../../../assets/img/svg/checked.png";
import infoNotChecked from "../../../assets/img/svg/not_checked.png"
import downloadIcon from "../../../assets/img/download.png"
import checkboxChecked from "../../../assets/img/svg/checkbox_on_light.png";
import checkboxNotChecked from "../../../assets/img/svg/checkbox_notChecked.png"
import { useHistory } from 'react-router-dom';
import FinishModalClose from "../../global/FinishModalClose"
import { CircularProgress } from '@material-ui/core';

export default function UserRoadmap(props) {
    const [scan, setScan] = useState();
    const [roadmap, setRoadmap] = useState();
    const [resultMapping, setResultMapping] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showAbandonModal, setShowAbandonModal] = useState(false);
    const [initalCompleted, setInitialCompleted] = useState();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [solution, setSolution] = useState();
    const [summary, setSummary] = useState();
    const [remainingResultsCount, setRemainingResultsCount] = useState();
    const [instances, setInstances] = useState();
    const [results, setResults] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const { REACT_APP_APIURL } = process.env;

    useEffect(() => {
        api.getFullScan(props.match.params.scanId)
            .then((response) => {
                if (response && response.roadmap) {
                    setScan(response);
                    var itemsIds = []
                    var alreadyCompleted = []
                    response.roadmap.items.forEach((element) => {
                        itemsIds.push(element["result_id"])
                        if (element.completed) {
                            alreadyCompleted.push(element["result_id"])
                        }
                    })
                    response.results.filter(item => itemsIds.includes(item._id)).forEach((r, idx) => {
                        resultMapping[r._id] = r;
                    })
                    setResultMapping(resultMapping);
                    setInitialCompleted(alreadyCompleted)
                    setRoadmap(response.roadmap)
                    console.log(response.roadmap);
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            });
        api.getScansResults(props.match.params.scanId).then(setResults);
    }, [props.match.params.scanId, resultMapping]);

    const abandonRoadmap = () => {
        // Ensure that we dont save uncompleted items to server
        roadmap.items = roadmap.items.map((item) => {
            const initial = initalCompleted.includes(item.result_id);
            if (!initial) {
                item.completed = false;
            }
            return item;
        })
        roadmap.abandoned = true;
        roadmap.archived = true;
        setRoadmap({ ...roadmap });
        updateResults();
        history.push(`/dashboard/`);
    }
    const updateResults = () => {
        api.updateRoadmap(roadmap)
            .then((response) => {
                setRoadmap(response);
                let newInitialCompleted = [];
                response.items.forEach((item) => {
                    if (item["completed"]) {
                        newInitialCompleted.push(item["result_id"])
                    }
                });
                setInitialCompleted(newInitialCompleted);

            })
            .finally(() => history.push(`/dashboards/company/${scan.company.id}/type/${scan.scan_type}/`));
    }

    const onItemChange = (item) => {
        roadmap.items.find(x => x.result_id === item.result_id).completed = !item.completed;
        console.log(roadmap)
        setRoadmap({ ...roadmap });
    };
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleAbandonClose = () => setShowAbandonModal(false);
    const handleAbandonShow = () => setShowAbandonModal(true);


    const getInfoForQuestion = (i, item) => {
        setCurrentIndex(i);
        setSolution(scan.results.find(x => x._id === item["result_id"]).solution)
        setSummary(scan.results.find(x => x._id === item["result_id"]).summary)
        setInstances(scan.results.find(x => x._id === item["result_id"]).instances)

    }

    const handleCheckbox = (item) => {
        const inital = initalCompleted.includes(item.result_id)
        const checked = inital || (item && item.completed);

        return (<img src={checked ? checkboxChecked : checkboxNotChecked} width="23" height="23" alt="checked" onClick={(e) => {
            if (inital) {
                return;
            }
            onItemChange(item);
        }} />)
    }

    const handlePdfDownload = () => {
        api.createUniqueKey(props.match.params.scanId)
            .then((response) => {
                if (response) {
                    const win = window.open(`${REACT_APP_APIURL}/api/pdf/scans/${props.match.params.scanId}/roadmap/?key=${response.key}`, "_blank")
                    win.focus();
                }
            });
    }

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <CircularProgress size={50} thickness={4} style={{ color: '#ff6500' }} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="col mx-45 mt-0 sa-content pe-0 roadmap-dashboard">
                <div className="container-parent">
                    <div className="info-check-conatiner">
                        <h3 className="title mt-5 mb-100">Cochez les vulnérabilités que vous avez corrigées.</h3>
                        <div className="chaeck-quesion-icon mb-3">
                            <div className="check-question">
                                <div className="checkbox-styles">

                                </div>
                                <p className="border-0"></p>
                                <div className="port-title">Port</div>
                            </div>
                            <img src="" alt="" />
                        </div>
                        <div className="check-box-conatiner">
                            {roadmap && roadmap.items.map((item, i) => {
                                const result = resultMapping[item.result_id];

                                return (
                                    <div className="chaeck-quesion-icon" key={i}>
                                        <div className="check-question">
                                            <div className="checkbox-styles">
                                                {handleCheckbox(item)}
                                            </div>
                                            <p className={initalCompleted && initalCompleted.includes(item.result_id) ? "text-disabled" : ""}>{result && result.issue}</p>
                                            <div className="port-div">{results && results.find((elem) => elem._id === item.result_id).port}</div>
                                        </div>
                                        <img src={i === currentIndex ? infoChecked : infoNotChecked} onClick={() => getInfoForQuestion(i, item)} alt="" />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="d-flex justify-content-around w-100 mt-auto">
                            <div className="check-wt-u-did-btns">
                                <div className="abandon-btn">
                                    <div className="custom-next-btn">
                                        <button className="btn si-primary-button mx-auto px-4 mt-5" onClick={handleAbandonShow}>
                                            <div className="btn-text">Abandon</div>
                                            <div className="close-arrow-icon"></div>
                                        </button>
                                    </div>
                                </div>
                                <div className="custom-next-btn">
                                    <button className="btn si-primary-button mx-auto px-4 mt-5" onClick={() => {
                                        setRemainingResultsCount(roadmap.items.filter((elem) => elem.completed === false).length)
                                        handleShow();
                                    }}>
                                        <div className="btn-text">Valider</div>
                                        <div className="right-arrow-icon"></div>
                                    </button>
                                </div>
                            </div>
                            <div className="pdf-btn" onClick={handlePdfDownload}>
                                <img src={downloadIcon} alt="download-btn" width="40" height="50" />
                                <span>FEUILLE DE ROUTE</span>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className={`pt-0 info-menu ${solution ? "" : "gone"}`}>
                            <h4>Nature de la vulnérabilité</h4>
                            <p className="mb-5">{summary}</p>
                            <h4>Action proposée</h4>
                            <p className="mb-5">{solution}</p>
                            {instances
                                && (
                                    <>
                                        <h4>Composants Concernées</h4>
                                        {instances && instances.map((item, i) => {
                                            return (
                                                <p key={i}>{item.uri}</p>
                                            )
                                        })}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <FinishModalClose
                        finishModalStatus={showModal}
                        handleComplete={updateResults}
                        handleCloseFinishModal={handleClose}
                        title={
                            "ATTENTION"
                        }
                        text={
                            <>
                                <p>La validation est irréversible. Elle doit être faite quand la correction est faite et mise à jour.</p>
                                <p>{(remainingResultsCount && remainingResultsCount > 0)
                                    ? `Il reste ${remainingResultsCount} vulnérabilité(s) à corriger.`
                                    : "Après cette validation votre feuille de route sera terminée!"}</p>
                            </>
                        }
                        btnText={"Valider"}
                    />
                    <FinishModalClose
                        finishModalStatus={showAbandonModal}
                        handleComplete={abandonRoadmap}
                        handleCloseFinishModal={handleAbandonClose}
                        title={"ATTENTION"}
                        text={
                            <>
                                <p>L’abandon est irréversible.</p>
                                <br />
                                <p>En cas d’abandon, les vulnérabilités corrigées sont enregistrées et améliorent votre situation.</p>
                            </>
                        }
                        btnText={"Abandon"}
                    />
                </div>
            </div>
        )
    }
}