import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';
import api from '../../../api/Api';
import ErrorMessages from '../../global/ErrorMessages';
import InfoModal from '../../global/InfoModal';

export default function UploadFile() {
    const [companies, setCompanies] = useState([]);
    const [errors, setErrors] = useState();
    const [displayModal, setDisplayModal] = useState();

    useEffect(() => {
        api.getCompanies().then(setCompanies);
    }, []);

    const handleSubmit = (values, { setSubmitting, ...config }) => {
        api.uploadScan(values)
            .then((response) => {
                setSubmitting(false);
                // display resulting modal accoridng to ticket: https://trello.com/c/um9spj46/91-dashboard-result-display
                setDisplayModal(true);
            })
            .catch((error) => {
                setSubmitting(false);
                setErrors(ErrorMessages.extractResponse(error.response));
            });
    };



    const fileInput = useRef(null);

    return (
        <div className="col mx-45 side-menu-padding-top">
            <Formik
                initialValues={{ company: '', scan_type: '', file: '' }}
                validationSchema={yup.object().shape({
                    company: yup.number().required(),
                    file: yup.mixed().required(),
                    scan_type: yup.mixed().required().oneOf(['website', 'server']),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, isSubmitting, values, ...formik }) => {
                    const handleClose = () => {
                        setDisplayModal(false);
                        formik.resetForm();
                        fileInput.current.value = '';
                    }

                    return (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row ps-100">
                                <div className="col-6 pe-4">
                                    <div className="col-content d-flex flex-column align-items-start">
                                        <div className="title">Company</div>
                                        <Field
                                            name="company"
                                            as="select"
                                            className={'form-select ' + (errors.company ? 'is-invalid' : '')}
                                        >
                                            <option value="">Select company</option>
                                            {companies &&
                                                companies.map((item, i) => (
                                                    <option value={item.id} key={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Field>
                                        <ErrorMessage component="div" name="company" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-6 ps-4 vline">
                                    <div className="col-content d-flex flex-column align-items-end">
                                        <div className="file-chooser">
                                            <div className="title">Scan Type</div>
                                            <Field
                                                name="scan_type"
                                                as="select"
                                                className={'form-select ' + (errors.scan_type ? 'is-invalid' : '')}
                                            >
                                                <option value="">Select type of scan uploaded</option>
                                                <option value="website">Website</option>
                                                <option value="server">Server</option>
                                            </Field>
                                            <ErrorMessage
                                                component="div"
                                                name="scan_type"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="file-chooser">
                                            <div className="title">File</div>
                                            <input
                                                name="file"
                                                type="file"
                                                id="formFile"
                                                ref={fileInput}
                                                className={'form-control ' + (errors.file ? 'is-invalid' : '')}
                                                onChange={(event) => {
                                                    formik.setFieldValue('file', event.currentTarget.files[0]);
                                                }}
                                            />
                                            <ErrorMessage component="div" name="file" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="col-content d-flex flex-column align-items-end mt-100">
                                        <button
                                            type="submit"
                                            className={
                                                'btn si-secondary-button sec-small' +
                                                (isSubmitting ? ' si-loading' : '')
                                            }
                                        >
                                            {isSubmitting ? 'Loading...' : 'Send'}
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <InfoModal
                                handleCloseInfoModal={handleClose}
                                infoModal={displayModal}
                                title={"Scan uploaded!"}
                                text={"File uploaded successfully."}
                            />
                        </form>
                    );
                }}
            </Formik>
            <div className="col-12">
                <ErrorMessages errors={errors} />
            </div>
        </div>
    );
}

// export default UploadFileFormik;
