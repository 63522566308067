import React from 'react';
import userImg from '../../../assets/img/user-img.png';
import { Dropdown } from 'react-bootstrap';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UserContext from '../../../UserContext';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccountBox from '@material-ui/icons/AccountBox';
import { useHistory } from 'react-router-dom';

export default function TopNavbar(props) {
    const showUserPanel = props.showUserPanel;
    const history = useHistory();

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    };
    return (
        <header>
            <div className="logo">
                <span>Les Oies du Cyber</span>
            </div>
            {showUserPanel && (
                <UserContext.Consumer>
                    {({ user, setUser }) => {
                        let displayName = '';
                        if (user) {
                            displayName = user.email;
                            if (user.first_name !== '' || user.last_name !== '') {
                                displayName = `${user.first_name} ${user.last_name}`;
                            }
                        }
                        return (
                            <div className="user-account">
                                {user && user.image ? (
                                    <img src={user.image} alt="user-img" className="user-img" />
                                ) : (
                                    <img src={userImg} alt="user-img" className="user-img" />
                                )}
                                <Dropdown>
                                    <Dropdown.Toggle variant="link" id="dropdown-basic" className="btn-user">
                                        {displayName}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="si-dropdown">
                                        {user && user.user_type === 'user' && (
                                            <>
                                                <Dropdown.Item
                                                    className="si-dropdown-item"
                                                    href="/profile/img"
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    <AddAPhotoIcon /> <span className="ms-1">Photo</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    className="si-dropdown-item"
                                                    href="/profile/name"
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    <AccountBox /> <span className="ms-1">Nom</span>
                                                </Dropdown.Item>
                                            </>
                                        )}
                                        <Dropdown.Item className="si-dropdown-item" onClick={() => logout()}>
                                            {' '}
                                            <ExitToAppIcon /> <span className="ms-1">Déconnexion</span>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        );
                    }}
                </UserContext.Consumer>
            )}
        </header>
    );
}
