import React, { useState, useEffect, useRef } from 'react';
import GaugeChart from "react-gauge-chart";
import api from "../../../api/Api";
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { CircularProgress } from "@material-ui/core";
import ScanDashboardOverlay from "./ScanDashboardOverlay";

export default function UserDashboard(props) {
    const [charts, setCharts] = useState([]);
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        api.getLatestScans(props.match.params.id, null, props.match.params.scanType, 1).then(setCharts)
            .finally(() => setTimeout(() => {
                setLoading(false)
            }, 500));
    }, [props.match.params.id, props.match.params.scanType]);

    const setColor = (value) => {
        if (value >= 7.5) {
            return 'brown';
        } else if (value < 7.5 && value >= 5) {
            return 'red';
        } else if (value < 5 && value >= 2.5) {
            return 'orange';
        } else if (value < 2.5) {
            return 'green';
        }
    };

    const setCriticalColor = (value) => {
        if (value === 4 || value === 5) {
            return 'brown';
        } else if (value === 2 || value === 3) {
            return 'orange';
        } else if (value === 1) {
            return 'green';
        }
    };

    const calculateNetRisk = (brut, critical) => {
        return (brut * critical) / 5;
    }

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <CircularProgress
                        size={50}
                        thickness={4}
                        style={{ color: "#ff6500" }}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className="col-8 mx-auto side-menu-padding-top ">
                    <Table borderless>
                        <thead>
                            <tr>
                                <th className="col-3 gaugeList-header text-center brb-1">Serveur</th>
                                <th className="col-3 gaugeList-header text-center brb-1">
                                    Risque brut
                                    <ScanDashboardOverlay text="Le risque brut est lié aux vulnérabilités du serveur." />
                                </th>
                                <th className="col-2 gaugeList-header text-center brb-1">
                                    Criticité
                                    <ScanDashboardOverlay text="La criticité est la capacité à fonctionner si le serveur est en panne. Elle est définie avec votre consultant." />
                                </th>
                                <th className="col-2 gaugeList-header text-center brb-1">
                                    Risque Net
                                    <ScanDashboardOverlay text=" Le risque net est le risque brut corrigé de la criticité." />
                                </th>
                                <th className="col-2 gaugeList-header text-center brb-1 no-border-right">Feuille de route</th>
                            </tr>
                        </thead>
                        <tbody>
                            {charts && charts.map((item, i) => (
                                <tr className="align-middle text-center" key={i}>
                                    <td>
                                        <p className="text-white-grey">{item.domain && item.domain.name ? item.domain.name : "TBA"}</p>
                                    </td>
                                    <td>
                                        <div className="col-10 mx-auto mb-4 mt-4" style={{ maxWidth: "500px" }}>
                                            <GaugeChart
                                                percent={Math.min(item.calculated_risk / 100, 1)}
                                                nrOfLevels={4}
                                                hideText={true}
                                                colors={["#008000", "#d48e05", "#d46604", "#c90700"]}
                                            />
                                            <p className="gauge-text fw-900"><span className="risque">Risque : </span><span className={`${setColor(item.calculated_risk / 10)}`}>{item.calculated_risk}</span></p>

                                        </div>
                                    </td>
                                    <td>
                                        <h3 className={"fw-900 " + setCriticalColor(item.critical)}>{item.critical}</h3>
                                    </td>
                                    <td>
                                        <h3 className={"fw-900 " + setColor(calculateNetRisk(item.calculated_risk, item.critical) / 10)}>{calculateNetRisk(item.calculated_risk, item.critical)}</h3>
                                    </td>
                                    <td className="no-border-right ps-5">
                                        {item && !item.roadmap
                                            ? (
                                                <Link className="text-decoration-none" to={`/dashboards/${item.id}/results`}>
                                                    <button className="btn si-primary-button mx-auto px-4">
                                                        <div className="btn-text">Créer</div>
                                                        <div className="right-arrow-icon"></div>
                                                    </button>
                                                </Link>
                                            )
                                            : (
                                                <Link className="text-decoration-none" to={`/scans/${item.id}/roadmap`}>
                                                    <button className="btn si-primary-button mx-auto px-4">
                                                        <div className="btn-text">Suivre</div>
                                                        <div className="right-arrow-icon"></div>
                                                    </button>
                                                </Link>
                                            )
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }
}