import React, { useState, useContext } from "react";
import UserContext from '../../../UserContext';
import { ErrorMessage, Field, Formik } from 'formik';
import * as yup from 'yup';
import api from "../../../api/Api";

export default function ChangeName() {

    const { user, setUser } = React.useContext(UserContext);

    const handleSubmit = (values, { setSubmitting, ...config }) => {
        api.updateUserFields(values, user.id).then((response) => {
            api.getCurrentUser()
                .then((response) => {
                    setUser(response);
                })
        });
    }

    return (
        <div className="col mx-45 my-auto">
            <h3 className="title text-center text-white mb-100">Modification de vos coordonnées</h3>
            <Formik
                initialValues={{ first_name: user.first_name, last_name: user.last_name }}
                validationSchema={
                    yup.object().shape({
                        first_name: yup.string()
                            .when(["last_name"], {
                                is: (last_name) => !last_name,
                                then: yup.string().required('At least one'),
                            }),
                        last_name: yup.string()
                            .when(["first_name"], {
                                is: (first_name) => !first_name,
                                then: yup.string().required('At least one'),
                            }),
                    }, ["last_name", "first_name"])
                }
                onSubmit={handleSubmit}
            >
                {({ errors, isSubmitting, values, ...formik }) => {
                    return (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="change-name-container mx-auto">
                                <div className="mb-4 d-flex align-items-center">
                                    <label htmlFor="last_name">Nom</label>
                                    <Field
                                        name="last_name"
                                        as="input"
                                        className={
                                            "form-control" + (errors.first_name ? " is-invalid" : "")
                                        }
                                    ></Field>
                                </div>
                                <div className="mb-4 d-flex align-items-center">
                                    <label htmlFor="first_name">Prénom</label>
                                    <Field
                                        name="first_name"
                                        as="input"
                                        className={
                                            "form-control" + (errors.last_name ? " is-invalid" : "")
                                        }
                                    ></Field>
                                </div>
                                <div className="mb-4 d-flex align-items-center">
                                    <label></label>
                                    <button type="submit" className="btn si-primary-button px-4 mb-3">
                                        <div className="btn-text">Suivre</div>
                                        <div className="right-arrow-icon"></div>
                                    </button>
                                </div>
                                {((errors.first_name) || (errors.last_name)) && (
                                    <div id="validationServerUsernameFeedback" className="alert si-form-alert mt-5 mx-0">
                                        <div className="col-4 error">
                                            <div className="notification-error"></div>
                                            <div className="error-msg">Attention!</div>
                                        </div>
                                        <div className="col-8">At least one is mandatory.</div>
                                    </div>
                                )}
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </div>
    )
}