import React, { useState } from 'react';
import api from '../../../api/Api';
import { Formik, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import InfoModal from '../../global/InfoModal';
import FinishModal from '../../global/FinishModal';

export default function UserNameRoadmap(props) {
    const [show, setShow] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [formValues, setFormValues] = useState();
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleInfoClose = () => setShowInfo(false);
    const handleInfoShow = () => setShowInfo(true);

    const updateRoadmapName = () => {
        api.getRoadmapWithScan(props.match.params.scanId)
            .then((response) => {
                if (formValues["name"] !== "") {
                    api.updateRoadmapFields(response.id, formValues)
                        .then((res) => {
                            handleClose();
                            history.push(`/scans/${props.match.params.scanId}/roadmap/`)
                        })
                } else {
                    handleClose();
                    history.push(`/scans/${props.match.params.scanId}/roadmap/`)
                }
            });
    }

    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                }}
                onSubmit={(values) => {
                    setFormValues(values);
                    handleShow();
                }}
            >
                {(formik) => {
                    const { errors, handleSubmit } = formik;
                    return (
                        <div className="col mx-45 side-menu-padding-top">
                            <form onSubmit={handleSubmit}>
                                <div className="finish-roadmap-wrapper">
                                    <div className="finish-contianer">
                                        <div className="header-conatiner">
                                            <div className="header">
                                                <div className="line"></div>
                                                <h1><p>Bravo !</p></h1>
                                                <div className="line"></div>
                                            </div>
                                            <h3><p>Votre feuille de route est prête !</p></h3>
                                        </div>
                                        <div className="middle">
                                            <p><p className="text-center">Donnez-lui un nom<br />pour l’enregistrer dans votre historique</p></p>
                                            <div className="input-container">
                                                <Field id="outlined-basic" name="name" as="input" placeholder="DRF" label="Name" className={"input" + (errors.name ? " is-invalid" : "")} />
                                                <ErrorMessage
                                                    component="div"
                                                    name="name"
                                                    className="invalid-feedback text-center mt-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="btn-sec">
                                            <div className="custom-next-btn">
                                                <button type="button" className="btn si-primary-button mx-auto px-4 mt-5" onClick={handleInfoShow}>
                                                    <div className="btn-text">AIDE</div>
                                                    <div className="right-info-icon"></div>
                                                </button>
                                            </div>
                                            <div className="custom-next-btn">
                                                <button type="submit" className="btn si-primary-button mx-auto px-4 mt-5">
                                                    <div className="btn-text">Valider</div>
                                                    <div className="right-arrow-icon"></div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    )
                }}
            </Formik>
            
            <FinishModal
                finishModalStatus={show}
                handleComplete={updateRoadmapName}
                handleCloseFinishModal={handleClose}
                text={
                    <>
                        <p>Votre feuille de route est enregistrée.</p>
                        <p>Votre feuille de route en cours apparaîtra dans votre tableau de bord.</p>
                        <p>Retrouvez vos feuilles de route une fois terminées en cliquant sur HISTORIQUE dans le menu principal.</p>
                    </>
                }
                btnText={"Valider"}
            />
            <InfoModal
                handleCloseInfoModal={handleInfoClose}
                infoModal={showInfo}
                title={"NOTRE CONSEIL"}
                text={
                    <>
                        <p className="text-left">Vous pourrez retrouver cette feuille de route, comme toutes les autres dans votre <span style={{ color: "#d35400" }}>Historique</span>.</p>
                    </>
                }
            />
        </>

    )
}