import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const getUser = () => {
    const userString = localStorage.getItem("user");
    const user = JSON.parse(userString);
    return user;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());


  const saveToken = (userToken) => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const saveUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  return {
    setToken: saveToken,
    setUser: saveUser,
    token,
    user,
  };
}
