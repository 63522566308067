import React from 'react';
import './Login.css';
import PropTypes from 'prop-types';
import logoImg from '../../../assets/img/logo.png';
import LoginForm from './LoginForm';

export default function Login({ setToken, setUser }) {
    return (
        <div className="height-90vh">
            <div className="login-page-container h-100">
                <div className="content">
                    <div className="logo-container">
                        <img src={logoImg} class="logo-img" alt="logo-img" />
                    </div>
                    <div className="form-title">Bienvenue dans votre plateforme SI</div>
                    <LoginForm setToken={setToken} setUser={setUser} />
                </div>
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
};
