import React, { useState, useRef } from 'react';
import infoNotChecked from "../../../assets/img/svg/checked2.png"
import { Overlay, Tooltip } from "react-bootstrap";

export default function ScanDashboardOverlay(props) {

    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <img src={infoNotChecked} className="ms-2" width="24" height="24" alt="info" ref={target} onClick={() => setShow(!show)} />
            <Overlay target={target.current} show={show} placement="bottom">
                {(attrs) => (
                    <Tooltip id="overlay1-example" {...attrs}>
                        {props.text}
                    </Tooltip>
                )}
            </Overlay>
        </>
    )
}