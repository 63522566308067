import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import uploadWhite from "../../../assets/img/upload-white-1.png";
import dashboard from "../../../assets/img/dashboard.png";
import calendar from "../../../assets/img/calendar-white-1.png";
import roadmapImg from "../../../assets/img/svg/roadmap.svg";


export default function SideMenu() {
  
  const [toggleMenu, setToggleMenu] = useState(true)

  return (
    <div className={"col d-flex flex-column side-menu-padding-top ms-45" + (toggleMenu? " open-menu" : " close-menu")}>
        <div className="menu">
            <div className="left-arrow"></div>
            <button className="btn btn-link hamburger-menu" onClick={(e) => {setToggleMenu(!toggleMenu)}}></button>
        </div>
        <ul>
            <li className="menu-link">
              <NavLink activeClassName="active" to="/upload">
                    <img className="menu-icon" src={uploadWhite} alt="" />
                    <span>Fichiers</span>
              </NavLink>
            </li>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/dashboard">
                    <img className="menu-icon" src={dashboard} alt="" />
                    <span>Tableau de bord</span>
                </NavLink>
            </li>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/calendar">
                    <img className="menu-icon" src={calendar} alt="" />
                    <span>Calendrier</span>
                </NavLink>
            </li>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/roadmap-history">
                    <img className="menu-icon" src={roadmapImg} alt="" />
                    <span>Historique</span>
                </NavLink>
            </li>
        </ul>
    </div>
  );
}
