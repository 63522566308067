import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import dashboard from "../../../assets/img/dashboard.png";
import roadmapImg from "../../../assets/img/svg/roadmap.svg";
import infoIcon from "../../../assets/img/svg/checked.png";

export default function SideMenu() {
  
  const [toggleMenu, setToggleMenu] = useState(true)

  return (
    <div className={"col d-flex flex-column side-menu-padding-top ms-45" + (toggleMenu? " open-menu" : " close-menu")}>
        <div className="menu">
            <div className="left-arrow"></div>
            <button className="btn btn-link hamburger-menu" onClick={(e) => {setToggleMenu(!toggleMenu)}}></button>
        </div>
        <ul>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/a-propos">
                    <img className="menu-icon" src={infoIcon} alt="" id="infoMenuIcon" />
                    <span>À propos</span>
                </NavLink>
            </li>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/dashboard">
                    <img className="menu-icon" src={dashboard} alt="" />
                    <span>Tableau de bord</span>
                </NavLink>
            </li>
            <li className="menu-link">
                <NavLink activeClassName="active" to="/roadmap-history">
                    <img className="menu-icon" src={roadmapImg} alt="" />
                    <span>Historique</span>
                </NavLink>
            </li>
        </ul>
    </div>
  );
}
