import axios from 'axios';

const { REACT_APP_APIURL } = process.env;

// TODO: Aici citesti token-ul si ii faci parse la fiecare utilizare. Nu putem sa
// facem sa fie stocat in memorie intr-o variabila global in acest fisier?
// Gen:
// var TOKEN = null;
const getToken = () => {
    // if (TOKEN !== null) {
    //   return TOKEN;
    // }
    // ??
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
};

const responseSuccessHandler = (response) => {
    return response;
};

const responseErrorHandler = (error) => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    return Promise.reject(error);
};

axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
);

async function loginUser(credentials) {
    return await axios
        .post(`${REACT_APP_APIURL}/api/auth/token/`, {
            username: credentials.email,
            password: credentials.password,
        })
        .then((response) => {
            return response.data;
        });
}

async function getCurrentUser() {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/auth/user/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function getSchedules(month, year) {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/schedules/?month=${month}&year=${year}`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function addSchedule(data) {
    const token = getToken();
    return await axios
        .post(`${REACT_APP_APIURL}/api/schedules/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function getLatestScans(companyId, domainId, scanType, full) {
    const token = getToken();
    let url = `${REACT_APP_APIURL}/api/scans/latest/?company=${companyId}`;

    if (domainId) {
        url = url + `&domain=${domainId}`;
    } else if (scanType) {
        url = url + `&scan_type=${scanType}`;
    }

    if (full) {
        url = url + `&full=1`;
    }
    return await axios
        .get(url, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function getScansResults(scanId, excludeValidated) {
    const token = getToken();
    let url = `${REACT_APP_APIURL}/api/scans/${scanId}/results`;
    if (excludeValidated) {
        url = url + `?exclude_validated=True`;
    }
    return await axios
        .get(url, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function getScan(scanId) {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/${scanId}/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function getScans() {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function getValidScansByCompany(companyId) {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/company/?company=${companyId}`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function getFullScan(scanId) {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/${scanId}/full`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function validateScan(scanId) {
    const token = getToken();
    const validate = { valid: true };

    return await axios
        .patch(`${REACT_APP_APIURL}/api/scans/${scanId}/`, validate, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}
async function updateScanFields(scanId, data) {
    const token = getToken();

    return await axios
        .patch(`${REACT_APP_APIURL}/api/scans/${scanId}/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}
async function updateUserFields(data, userId) {
    const token = getToken();

    return await axios
        .patch(`${REACT_APP_APIURL}/api/users/${userId}/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}
async function getCompanies() {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/companies/`, {
            // TODO: De vazut cum facem cu axios interceptor. Sa nu trebuiasca sa setam la fiecare request.
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function getCompany(companyId) {
    const token = getToken();
    return await axios
        .get(`${REACT_APP_APIURL}/api/companies/${companyId}/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function uploadScan({ file, company, scan_type }) {
    const token = getToken();
    const formData = new FormData();

    formData.append('name', file.name);
    formData.append('file', file);
    formData.append('company', parseInt(company, 10));
    formData.append('scan_type', scan_type);

    return await axios
        .post(`${REACT_APP_APIURL}/api/scans/`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function uploadUserImage({ image }, userId) {
    const token = getToken();
    const formData = new FormData();

    formData.append('image', image);

    return await axios
        .post(`${REACT_APP_APIURL}/api/users/${userId}/avatar/`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function getArchivedRoadmap() {
    const token = getToken();

    return await axios
        .get(`${REACT_APP_APIURL}/api/roadmaps/archived/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function createRoadmap(data) {
    const token = getToken();

    return await axios
        .post(`${REACT_APP_APIURL}/api/roadmaps/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}

async function updateRoadmap(data) {
    const token = getToken();

    return await axios
        .put(`${REACT_APP_APIURL}/api/roadmaps/${data.id}/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}

async function updateRoadmapFields(roadmapId, data) {
    const token = getToken();

    return await axios
        .patch(`${REACT_APP_APIURL}/api/roadmaps/${roadmapId}/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}

async function getRoadmapWithScan(scanId) {
    const token = getToken();

    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/${scanId}/roadmap/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}

async function getRoadmapsByScan(scanId) {
    const token = getToken();

    return await axios
        .get(`${REACT_APP_APIURL}/api/scans/${scanId}/history/`, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => response.data);
}

async function createUniqueKey(scanId) {
    const token = getToken();

    return await axios
        .post(`${REACT_APP_APIURL}/api/scans/${scanId}/ukey/`, null, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function sendMailToConsultant(data) {
    const token = getToken();

    return await axios
        .post(`${REACT_APP_APIURL}/api/send-mail/consultant/`, data, {
            headers: {
                Authorization: 'Token ' + token,
            },
        })
        .then((response) => {
            return response.data;
        });
}
async function passwordReset(data) {
    return await axios.post(`${REACT_APP_APIURL}/api/password_reset/`, data).then((response) => {
        return response.data;
    });
}

async function confirmPasswordReset(data) {
    return await axios.post(`${REACT_APP_APIURL}/api/password_reset/confirm/`, data).then((response) => {
        return response.data;
    });
}

const api = {
    loginUser,
    getCompanies,
    getCompany,
    uploadScan,
    uploadUserImage,
    getCurrentUser,
    getSchedules,
    addSchedule,
    getLatestScans,
    getScansResults,
    getScan,
    validateScan,
    createRoadmap,
    getFullScan,
    updateRoadmap,
    updateRoadmapFields,
    getRoadmapWithScan,
    getArchivedRoadmap,
    updateScanFields,
    sendMailToConsultant,
    passwordReset,
    confirmPasswordReset,
    getRoadmapsByScan,
    getScans,
    createUniqueKey,
    updateUserFields,
    getValidScansByCompany,
};

export default api;
