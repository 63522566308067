import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik, Field, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import DateTimePicker from "react-datetime-picker";
import api from "../../../../api/Api";

export const DateTimePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DateTimePicker
      {...field}
      {...props}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};
class ScheduleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      isSaving: false,
      errorsMessage: null,
    };
  }

  getUserCompanies = async () => {
    await api.getCompanies().then((response) => {
      this.setState({ companies: response });
    });
  };

  componentDidMount() {
    this.getUserCompanies();
  }

  closeModal = () => {
    this.props.onHide();
    this.setState({ errorsMessage: null });
  };

  getSchedules = (date) => {
    this.props.getSchedules(date);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Formik
          initialValues={{
            company: "",
            datetime: this.props.timeframe,
            comments: "",
          }}
          validationSchema={Yup.object().shape({
            company: Yup.string().required("No company provided."),
          })}
          onSubmit={async (values, actions) => {
            const response = await api
              .addSchedule(values)
              .catch((error) => {
                if (error.response) {
                  const errors = [];
                  for (const value of Object.values(error.response.data)) {
                    errors.push(value);
                  }
                  var errorsList = [].concat.apply([], errors);
                  this.setState({ errorsMessage: errorsList });
                } else {
                  this.setState({ errorsMessage: ["Unknown Error"] });
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });

            if (response) {
              this.getSchedules(new Date(response.datetime));
              this.closeModal();
            }
          }}
        >
          {(formik) => {
            const { errors, handleSubmit, isSubmitting } = formik;
            return (
              <form onSubmit={handleSubmit}>
                <Modal.Header closeButton={!isSubmitting}>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="mb-3">
                    <label htmlFor="company">Company</label>
                    <Field
                      name="company"
                      as="select"
                      className={
                        "form-control" + (errors.company ? " is-invalid" : "")
                      }
                    >
                      <option value="">Please choose a company!</option>
                      {this.state.companies &&
                        this.state.companies.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        ))}
                    </Field>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="datetime">Datetime</label>
                    <DateTimePickerField
                      name="datetime"
                      className={
                        "form-control" + (errors.datetime ? " is-invalid" : "")
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="comments">Comments</label>
                    <Field
                      name="comments"
                      as="textarea"
                      className={
                        "form-control" + (errors.comments ? " is-invalid" : "")
                      }
                    ></Field>
                  </div>
                  {this.state.errorsMessage &&
                    this.state.errorsMessage.map((item, i) => (
                      <div
                        key={i}
                        id="validationServerUsernameFeedback"
                        className="alert si-form-alert"
                      >
                        <div className="col-3 error">
                          <div className="notification-error"></div>
                          <div className="error-msg">Error!</div>
                        </div>
                        <div className="col-9">{item}</div>
                      </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={isSubmitting}
                    variant="secondary"
                    onClick={this.closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="success"
                    type="submit"
                    className=""
                  >
                    Save
                  </Button>
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default ScheduleModal;
