
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function InfoModal({
    handleCloseInfoModal = (f) => f,
    infoModal = false,
    ...props
}) {

    return (
        <Dialog
            open={infoModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseInfoModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="dashboard-modal-container"
        >
            <div className="info-modal-content">
                <span onClick={handleCloseInfoModal} className="close-icon"></span>
                {/* <DialogTitle id="alert-dialog-slide-title">
                    <span>{props.title}</span>
                </DialogTitle> */}
                <div className="img-text-content">
                    <DialogContent>
                        <div id="alert-dialog-slide-description">
                            {props.text}
                        </div>
                    </DialogContent>
                </div>
            </div>
        </Dialog>
    );
}