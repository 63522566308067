import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import Line from '../../../assets/img/hline.png';
import { Link } from 'react-router-dom';
import infoNotChecked from '../../../assets/img/svg/checked.png';
import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

export default function ScanHistory(props) {
    const [scans, setScans] = useState();
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(true);
    const { REACT_APP_APIURL } = process.env;
    const history = useHistory();

    useEffect(() => {
        api.getValidScansByCompany(props.match.params.companyId)
            .then((resp) => {
                getRoadmapScanType(resp);
                api.getCompany(props.match.params.companyId).then(setCompany);
            })
            .finally(() =>
                setTimeout(() => {
                    setLoading(false);
                }, 500)
            );
    }, []);

    const formatDate = (date) => {
        return new Date(date);
    };

    const translateKey = (string) => {
        if (string === 'server') {
            return 'SERVEUR';
        } else if (string === 'website') {
            return 'SITE INTERNET';
        }
    };

    const getRoadmapScanType = (data) => {
        var holder = {};

        if (data) {
            data.forEach(function (d) {
                if (!holder.hasOwnProperty(d.scan_type)) {
                    holder[d.scan_type] = [];
                }
                holder[d.scan_type].push(d);
            });
        }
        setScans(holder);
    };

    const checkNumber = (data) => {
        if (data < 10) {
            return 'single-number';
        } else {
            return 'two-number';
        }
    };

    const handlePdfDownload = (scanId) => {
        api.createUniqueKey(scanId)
            .then((response) => {
                if (response) {
                    const win = window.open(`${REACT_APP_APIURL}/api/pdf/scans/${scanId}/?key=${response.key}`, "_blank")
                    win.focus();
                }
            });
    }


    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    <CircularProgress size={50} thickness={4} style={{ color: '#ff6500' }} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="col mx-45 side-menu-padding-top roadmap-history">
                <div className="title-scan-history text-center">
                    <h3>{company && company.name}</h3>
                    <p>Elément scanné / Date du scan / Feuilles de route</p>
                </div>
                <div className="home-container">
                    <div className="archive-container">
                        {scans &&
                            Object.keys(scans).map((item) => (
                                <div className="archive-supervision">
                                    <h3>{translateKey(item)}</h3>
                                    <img className="img-line" src={Line} alt="" />
                                    <ul>
                                        {scans[item] &&
                                            scans[item].map((elem, j) => (
                                                <div className="archive-supervision-div" key={j}>
                                                    <img className="me-3" src={infoNotChecked} alt="info" onClick={() => handlePdfDownload(elem.id)} />
                                                    <span className="domain-span">{elem.domain.name}</span>
                                                    <span>
                                                        <span className="date">
                                                            {formatDate(elem.created).toLocaleDateString('en-GB')}
                                                        </span>
                                                    </span>
                                                    <Link
                                                        className="text-white text-decoration-none"
                                                        to={{
                                                            pathname: `/roadmap-history/${props.match.params.companyId}/${elem.id}`,
                                                            state: {
                                                                title: elem.domain.name,
                                                                date: formatDate(elem.created).toLocaleDateString(
                                                                    'en-GB'
                                                                ),
                                                            },
                                                        }}
                                                    >
                                                        <div
                                                            className={`history-number-btn ${checkNumber(
                                                                elem.count_archived_roadmaps
                                                            )}`}
                                                        >
                                                            <h6>{elem.count_archived_roadmaps}</h6>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                    </ul>
                                </div>
                            ))}
                    </div>
                    <button
                        className="btn si-primary-button mx-auto px-4 mt-5"
                        onClick={() => history.push("/roadmap-history")}
                    >
                        <div className="left-arrow-icon"></div>
                        <div className="btn-text">RETOUR</div>
                    </button>
                </div>
            </div>
        );
    }
}
