import * as React from "react";
import ScheduleModal from "./Schedule/ScheduleModal";
import ScheduleView from "./Schedule/ScheduleView";
import api from "../../../api/Api";

export default class Demo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
      timeframe: null,
      data: [],
    };
  }

  componentDidMount() {
    this.getSchedules(new Date());
  }

  onModalClose = () => {
    this.setState({ modalShow: false });
  };

  onShowModal = (timeframe) => {
    this.setState({ modalShow: true, timeframe });
  };

  getSchedules = (date) => {
    api
      .getSchedules(date.getMonth() + 1, date.getFullYear())
      .then((response) => {
        this.setState({
          data: response.map((appointment) => {
            return {
              title: appointment.company.name.toString(),
              startDate: new Date(appointment.datetime),
              id: appointment.id,
            };
          }),
        });
      });
  }

  render() {
    return (
      <div className="col mx-45 side-menu-padding-top">
        <ScheduleView onCellDoubleClick={this.onShowModal} getSchedules={this.getSchedules} data={this.state.data}/>
        <ScheduleModal
          show={this.state.modalShow}
          onHide={this.onModalClose}
          timeframe={this.state.timeframe && this.state.timeframe.start}
          getSchedules={this.getSchedules}
        />
      </div>
    );
  }
}
