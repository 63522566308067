import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import vLine from '../../assets/img/vline.png';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FinishModal({
    handleCloseFinishModal = (f) => f,
    handleComplete = (f) => f,
    type,
    finishModalStatus = false,
    routePage,
    validateRoadmap = false,
    ...props
}) {
    return (
        <Dialog
            open={finishModalStatus}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseFinishModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="finish-modal"
        >
            <div className="info-modal-content">
                <DialogTitle id="alert-dialog-slide-title">
                    <span>{props.title}</span>
                </DialogTitle>
                <div className="img-text-content">
                    <img src={vLine} alt="" className="left-line" />
                    <DialogContent>
                        <div id="alert-dialog-slide-description">
                            {props.text}
                        </div>
                    </DialogContent>
                    <img src={vLine} alt="" className="right-line" />
                    <DialogActions>
                        <button className="btn si-primary-button mx-auto me-5 px-4 mt-5" onClick={() => {
                            handleCloseFinishModal();
                        }}>
                            <div className="btn-text">ANNULER</div>
                            <div className="close-arrow-icon"></div>
                        </button>
                        <button className="btn si-primary-button  ms-5 px-4 mt-5" onClick={() => {
                            handleCloseFinishModal();
                            handleComplete();
                        }}>
                            <div className="btn-text">{props.btnText}</div>
                            <div className="right-arrow-icon"></div>
                        </button>
                    </DialogActions>
                </div>
            </div>
        </Dialog>
    );
}