import React, { useState, useEffect } from 'react';
import GaugeChart from "react-gauge-chart";
import api from "../../../api/Api";
import { Link } from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";

export default function UserDashboard() {
    const [charts, setCharts] = useState();
    const [firstRow, setFirstRow] = useState();
    const [secondRow, setSecondRow] = useState();
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        setUser(user)

        const splitArrays = (data) => {
            if (data) {
                var grouped = data.reduce(function (r, a, i) {
                    if (!i || r[r.length - 1][0].scan_type !== a.scan_type) {
                        return r.concat([[a]]);
                    }
                    r[r.length - 1].push(a);
                    return r;
                }, []);
            }
            grouped.map((item, i) => (
                grouped[i] = getWorstGauge(item)
            ))
            if (grouped.length < 2 && grouped[0]["scan_type"] === "website") {
                grouped.push({ scan_type: "server", calculated_risk: 0 })
            } else if (grouped.length < 2 && grouped[0]["scan_type"] === "server") {
                grouped.push({ scan_type: "website", calculated_risk: 0 })
            }
            grouped.sort((a, b) => (a.scan_type > b.scan_type) ? 1 : -1)
            return grouped;
        }

        api
            .getLatestScans(parseInt(user.companies[0]))
            .then((response) => {
                getGauges(response);
                getWorstGauge(response);
                setSecondRow(splitArrays(response));
                setCharts(response);

            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            })
    }, []);
    const sortObj = (obj) => {
        return Object.keys(obj).sort().reduce(function (result, key) {
            result[key] = obj[key];
            return result;
        }, {});
    }

    const getGauges = (data) => {
        var holder = { "website": 0, "server": 0 };
        var average = { "website": 0, "server": 0 };
        if (data) {
            data.forEach(function (d) {

                if (holder.hasOwnProperty(d.scan_type)) {
                    holder[d.scan_type] = holder[d.scan_type] + (d.calculated_risk * d.critical);
                    average[d.scan_type] = average[d.scan_type] + 1
                } else {
                    holder[d.scan_type] = d.calculated_risk * d.critical;
                    average[d.scan_type] = 1
                }
            });
            for (const [key, value] of Object.entries(holder)) {
                if (average[key] === 0) {
                    holder[key] = 0
                } else {
                    holder[key] = value / (average[key] * 5)
                }
            }
            setFirstRow(sortObj(holder));
        }
    }

    const getWorstGauge = (data) => {
        if (data) {
            var max = data.reduce(function (prev, current) {
                if (+current.calculated_risk > +prev.calculated_risk) {
                    return current;
                } else {
                    return prev;
                }
            });
        }
        return max;
    }



    const translateKey = (string) => {
        if (string === "server") {
            return "SERVEUR"
        } else if (string === "website") {
            return "SITE INTERNET"
        }
    }

    const colorText = (data) => {
        if (data >= 75) {
            return "brown";
        }
        if (data >= 50) {
            return "red";
        }
        if (data >= 25) {
            return "orange";
        }
        return "green";
    }

    const colorFirstRowText = (item) => {
        if (firstRow[item] === 0 && charts.filter((elem) => elem.scan_type === item).length === 0) {
            return "disabled-gauge";
        }
        return colorText(firstRow[item]);
    }

    const colorGauge = (item) => {
        if (firstRow[item] === 0 && charts.filter((elem) => elem.scan_type === item).length === 0) {
            return ["#666666", "#666666", "#666666", "#666666"];
        } else {
            return ["#008000", "#d48e05", "#d46604", "#c90700"];
        }
    }

    const format = (num, decimals) => {
        return num.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
        
    }

    if (loading) {
        return (
            <div className="col mx-45 my-auto user-dashboard">
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <CircularProgress
                        size={50}
                        thickness={4}
                        style={{ color: "#ff6500" }}
                    />
                </div>
            </div>
        )
    } else {
        if (charts) {
            return (
                <div className="col mx-45 my-auto user-dashboard">
                    <div className="row justify-content-center">
                        <div className="col-1 my-auto text-center me-4">
                            <p className="text-white-grey rows-title">Risque net moyen <br /></p>
                        </div>
                        {firstRow &&
                            Object.keys(firstRow).map((item, i) => (
                                <div className="col-3 text-center gradient-right-top" key={i} style={{ maxWidth: "500px" }}>
                                    <h3>{translateKey(item)}</h3>
                                    <GaugeChart
                                        className="gauge"
                                        percent={Math.min(firstRow[item] / 100, 1)}
                                        nrOfLevels={4}
                                        hideText={true}
                                        colors={colorGauge(item)}
                                    />
                                    <p className="gauge-text"><span className="risque">Risque : </span><span className={`${colorFirstRowText(item)}`}>{format(firstRow[item])}</span></p>
                                    <div className="gauge-underline"></div>
                                </div>
                            ))}
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-1 my-auto text-center me-4">
                            <p className="text-white-grey rows-title">Risque brut maximum <br /></p>
                        </div>
                        {secondRow &&
                            secondRow.map((item, i) => (
                                <div className="col-3 text-center gradient-right-bottom-grey" key={i} style={{ maxWidth: "500px" }}>
                                    {charts && charts.filter((elem) => elem.scan_type === item.scan_type).length > 0
                                        ? (
                                            <>
                                                <GaugeChart
                                                    className="gauge"
                                                    percent={Math.min(item.calculated_risk / 100, 1)}
                                                    nrOfLevels={4}
                                                    colors={["#008000", "#d48e05", "#d46604", "#c90700"]}
                                                    hideText={true}
                                                />
                                                <p className="gauge-text"><span className="risque">Risque : </span><span className={`${colorText(item.calculated_risk)}`}>{format(item.calculated_risk)}</span></p>

                                            </>
                                        )
                                        : (
                                            <>
                                                <GaugeChart
                                                    className="gauge"
                                                    percent={0}
                                                    nrOfLevels={4}
                                                    colors={["#666666", "#666666", "#666666", "#666666"]}
                                                    hideText={true}
                                                />
                                                <p className="gauge-text"><span className="risque">Risque : </span><span className="disabled-gauge">0</span></p>
                                            </>
                                        )
                                    }
                                </div>
                            ))}
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-1 me-4"></div>
                        {firstRow && Object.keys(firstRow).map((item, i) => (
                            <div className="col-3 gradient-right-bottom" key={i} style={{ maxWidth: "500px" }}>
                                <Link className="text-decoration-none" to={`/dashboards/company/${parseInt(user.companies[0])}/type/${item}`}>
                                    <button className="btn si-primary-button sec-med mx-auto px-4 user-dash-btns">
                                        <div className="btn-text">DETAIL</div>
                                        <div className="right-arrow-icon"></div>
                                    </button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="col mx-45 my-auto user-dashboard">
                    <div className="row justify-content-center">
                        <div className="col-1 my-auto text-center me-4">
                            <p className="text-white-grey rows-title">Risque net moyen <br /></p>
                        </div>
                        <div className="col-3 text-center gradient-right-top" style={{ maxWidth: "500px" }}>
                            <h3>SERVEUR</h3>
                            <GaugeChart
                                className="gauge"
                                percent={0}
                                nrOfLevels={4}
                                colors={["#666666", "#666666", "#666666", "#666666"]}
                                hideText={true}
                            />
                            <p className="gauge-text"><span className="risque">Risque : </span><span className="disabled-gauge">0</span></p>
                            <div className="gauge-underline"></div>
                        </div>
                        <div className="col-3 text-center gradient-right-top" style={{ maxWidth: "500px" }}>
                            <h3>SITE INTERNET</h3>
                            <GaugeChart
                                className="gauge"
                                percent={0}
                                nrOfLevels={4}
                                colors={["#666666", "#666666", "#666666", "#666666"]}
                                hideText={true}
                            />
                            <p className="gauge-text"><span className="risque">Risque : </span><span className="disabled-gauge">0</span></p>
                            <div className="gauge-underline"></div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col-1 my-auto text-center me-4">
                            <p className="text-white-grey rows-title">Risque brut maximum <br /></p>
                        </div>
                        <div className="col-3 text-center gradient-right-bottom" style={{ maxWidth: "500px" }}>
                            <GaugeChart
                                className="gauge"
                                percent={0}
                                nrOfLevels={4}
                                colors={["#666666", "#666666", "#666666", "#666666"]}
                                hideText={true}
                            />
                            <p className="gauge-text"><span className="risque">Risque : </span><span className="disabled-gauge">0</span></p>

                        </div>
                        <div className="col-3 text-center gradient-right-bottom" style={{ maxWidth: "500px" }}>
                            <GaugeChart
                                className="gauge"
                                percent={0}
                                nrOfLevels={4}
                                colors={["#666666", "#666666", "#666666", "#666666"]}
                                hideText={true}
                            />
                            <p className="gauge-text"><span className="risque">Risque : </span><span className="disabled-gauge">0</span></p>

                        </div>
                    </div>
                </div>
            );
        }
    }
}