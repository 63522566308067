import React, { useState, useEffect } from 'react';
import api from '../../../api/Api';
import { useHistory } from "react-router-dom";
import checkboxChecked from "../../../assets/img/svg/checkbox_on_light.png";
import infoNotChecked from "../../../assets/img/svg/checked2.png"
import UserDashboardModal from '../../global/UserDashboardModal';

export default function UserDashboardResults(props) {
    const [results, setResults] = useState([]);
    const [scan, setScan] = useState();
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [displayModal, setDisplayModal] = useState();
    const [summary, setSummary] = useState("");
    const [solution, setSolution] = useState("");
    const [instances, setInstances] = useState();
    const history = useHistory();


    useEffect(() => {
        api.getScansResults(props.match.params.scanId, true).then(setResults);
        api.getScan(props.match.params.scanId).then(setScan);
    }, [props.match.params.scanId]);

    const setBackground = (value) => {
        if (value >= 9) {
            return 'brown';
        } else if (value < 9 && value >= 7) {
            return 'red';
        } else if (value < 7 && value >= 5) {
            return 'orange';
        } else if (value < 5) {
            return 'green';
        }
    };

    const calculateRiskPoints = (value) => {
        let RISK_POINTS_THRESHOLD = [];
        let RISK_POINTS_VALUES = [];

        if (scan && scan.scan_type === "website") {
            RISK_POINTS_THRESHOLD = [9, 6, 3, 1];
            RISK_POINTS_VALUES = [100, 10, 1, 0];
        } else {
            RISK_POINTS_THRESHOLD = [9, 8, 7, 6, 5, 4];
            RISK_POINTS_VALUES = [108, 36, 12, 4, 1, 0];
        }

        for (const [i, th] of RISK_POINTS_THRESHOLD.entries()) {
            if (value >= th) {
                return RISK_POINTS_VALUES[i];
            }
        }
        return 0;
    };

    const calculateFutureRisk = (value) => {
        var total = 0;
        if (value === 0 && scan) {
            return scan.calculated_risk;
        }
        for (let i = value; i < value + 10; i++) {
            if (results[i]) {
                total = total + calculateRiskPoints(results[i].value);
            }
        }
        return total;
    };

    const createRoadmap = (index) => {
        var resultIds = [];
        for (var i = 0; i <= index; i++) {
            resultIds.push({ "result_id": results[i]._id })
        }
        api.createRoadmap({ "scan": parseInt(props.match.params.scanId), "items": resultIds }).then((response) => history.push(`/scans/${props.match.params.scanId}/roadmap/name/`))
    }

    const handleClose = () => {
        setDisplayModal(false);
    }

    return (
        <div className="col custom-content justify-content-between mt-0">
            <div className="title-results mt-auto">
                <h4>Choisissez les vulnérabilités à corriger</h4>
                <p>Le traitement se fait toujours en priorisant les vulnérabilités les plus sévères.</p>
            </div>
            <div className="results-box agreements-box gradient-left gradient-right mt-auto">
                <div className="row">
                    <div className="col-12">
                        <div className="row text-grey title-rectangle">
                            <div className="col-auto ps-0 dash-info-col title-text">
                                Détail
                            </div>
                            <div className="col-auto dash-port-col title-text">Port</div>
                            <div className="col-6 dash-nature-col title-text">Nom de la vulnérabilité</div>
                            <div className="col-auto ms-auto title-text">
                                <span>Sévérité</span>
                            </div>
                        </div>
                        <div className="the-slector">
                            {results && results.length <= 0 &&
                                <h1 className="text-center mt-5">No result for this Scan</h1>
                            }
                            {results &&
                                results.map((item, i) => (
                                    <div
                                        className={
                                            'slector-item align-items-center justify-content-start ' +
                                            (i <= currentIndex ? ' cur-slected-item' : '')
                                        }
                                        key={i}
                                    >
                                        <p className="question-text text-port">
                                            <img width="26"
                                                height="26"
                                                src={infoNotChecked} alt="infoNotChecked2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDisplayModal(true);
                                                    setSummary(item.summary);
                                                    setSolution(item.solution);
                                                    setInstances(item.instances);
                                                }} />
                                        </p>
                                        <div
                                            onClick={() => setCurrentIndex(i)}
                                            className={
                                                'slector-item w-100 border-0'
                                            }
                                            key={i}
                                        >
                                            <p className="question-text text-port p-port">{item.port}</p>
                                            <p className="question-text text-summary">{item.issue}</p>
                                            <div className="d-flex justify-content-end align-items-center w-100">
                                                <div className={"v-colored-rectangle bg-" + setBackground(item.value)}></div>
                                                <p className="impact-text ms-3">{item.value}</p>
                                                <div className="checkbox-div text-center">
                                                    <img src={checkboxChecked} className="check-icon" width="28" height="28" alt="checkbox"></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w-100 roadmap-legend mt-auto">
                <div className="col-2 gradient-left color-legend">
                    <h6>Sévérité de la vulnérabilité détectée</h6>
                    <ul className="mb-0">
                        <li><div className="color-square bg-brown"></div>Très élevée</li>
                        <li><div className="color-square bg-red"></div>Elevée</li>
                        <li><div className="color-square bg-orange"></div>Moyenne</li>
                        <li><div className="color-square bg-green"></div>Faible</li>
                    </ul>
                </div>
                <div className="col-8">
                    <div className="col-8 mx-auto gradient-left px-12px">
                        <h5 className="risque-text d-flex">Nombre de vulnérabilités à corriger : <span className="ms-auto">{currentIndex + 1}</span></h5>
                        <h5 className="risque-text d-flex">Niveau de risque actuel : <span className={"ms-auto " + setBackground(scan && scan.calculated_risk / 10)}>{scan && scan.calculated_risk}</span></h5>
                        <h5 className="risque-text d-flex">Niveau de risque après correction : <span className={"ms-auto " + setBackground(calculateFutureRisk(currentIndex + 1) / 10)}>{calculateFutureRisk(currentIndex + 1)}</span>
                        </h5>
                    </div>
                </div>
                <div className="col-2">
                    {currentIndex > -1 && (
                        <button
                            className="btn si-primary-button ms-auto px-4 mt-5"
                            onClick={() => createRoadmap(currentIndex)}
                        >
                            <div className="btn-text">Valider</div>
                            <div className="right-arrow-icon"></div>
                        </button>
                    )}
                </div>
            </div>
            <UserDashboardModal
                handleCloseInfoModal={handleClose}
                infoModal={displayModal}
                title={"Nature de la vulnérabilité"}
                text={
                    <div className="user-dashboard-modal-text">
                        <h4>Nature de la vulnérabilité</h4>
                        <p className="mb-5">{summary}</p>
                        <h4>Action proposée</h4>
                        <p className="mb-5">{solution}</p>
                        {instances
                            && (
                                <>
                                    <h4>Composants Concernées</h4>
                                    {instances && instances.map((item, i) => {
                                        return (
                                            <p key={i}>{item.uri}</p>
                                        )
                                    })}
                                </>
                            )
                        }

                    </div>
                }
            />
        </div>
    );
}
